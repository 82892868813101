import MessageBanner from '@/components/Banner/MessageBanner';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { Box, Text, theme } from '@fivehealth/botero';
import moment from 'moment';

export const TemporaryDowtimeBanner: React.FC<{ isHideable?: boolean }> = ({ isHideable }) => {
  const currDate = moment(moment().format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH_TIME_1));
  const endDate = moment('2025-02-24T23:59:59+08:00'); // Feb. 1, 2025 7.00pm SGT

  const isHideBanner = currDate.isAfter(endDate);

  return !isHideBanner ? (
    <MessageBanner
      open
      sticky
      type="error"
      hideClose={!isHideable}
      content={
        <Box>
          <Text as="span" color={theme.colors.danger}>
            Due to an issue with Parkway Radiology’s servers, we are unable to process new orders. Please try again later.
          </Text>
        </Box>
      }
      bannerProps={{ mb: 2, mt: -2 }}
    />
  ) : null;
};
